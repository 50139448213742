<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="3" v-if="!params.all">
          <v-autocomplete
            dense
            flat
            outlined
            return-object
            v-model="account"
            :items="accounts"
            background-color="white"
            append-icon="mdi-magnify"
            item-text="accountName"
            @change="onChangeAccount"
            :filter="filterObject"
          >
            <template v-slot:item="data">
              <v-list-item v-on="data.on">
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.accountNumber"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="params.startDate"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="3">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="params.endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-checkbox v-model="params.all" label="All"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="2">
          <v-btn color="primary" @click="getReports" class="mr-2">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
          <v-btn color="success" @click="excel">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-col cols="12">
      <v-row>
        <v-col cols="3">ACCOUNT NUM : {{ report.accountNumber }}</v-col>
        <v-col cols="3">ACCOUNT NAME :{{ report.accountName }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    DATE
                  </th>
                  <th class="text-center">
                    DESCRIPTION
                  </th>
                  <th class="text-center">
                    REFF
                  </th>
                  <th class="text-center">
                    CURR
                  </th>
                  <th class="text-center">
                    DEBET
                  </th>
                  <th class="text-center">
                    CREDIT
                  </th>
                  <th class="text-center">
                    BALANCE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td></td>
                  <td>SALDO AWAL</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{ formatPrice(report.beginBalance) }}</td>
                </tr>
                <tr v-for="item in report.items" :key="item.description" class="text-center">
                  <td>{{ item.date }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.reference }}</td>
                  <td>{{ item.currency }}</td>
                  <td>{{ formatPrice(item.debit) }}</td>
                  <td>{{ formatPrice(item.credit) }}</td>
                  <td>{{ formatPrice(item.balance) }}</td>
                </tr>
                <tr class="text-center">
                  <td colspan="4">TOTAL</td>
                  <td>{{ formatPrice(report.totalDebit) }}</td>
                  <td>{{ formatPrice(report.totalCredit) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col> -->
    <v-col cols="12" v-if="view">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="700px"
      ></iframe>
    </v-col>
    <v-col cols="12" v-if="viewIdr">
      <iframe
        :src="urlIdr"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="700px"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";
import "jspdf-autotable";
import { jsPDF } from "jspdf";

export default {
  name: "report-general-ledger",
  data() {
    return {
      params: {
        startDate: moment()
          .clone()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .clone()
          .endOf("month")
          .format("yyyy-MM-DD"),
        id: 0,
        all: false,
        one: true,
      },

      menuStartDate: false,
      menuEndDate: false,
      view: false,
      viewIdr: false,
      report: {},
      accounts: [],
      account: {},
      url: "",
      urlIdr: "",
    };
  },
  methods: {
    async getAccounts() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/getListAccountReportGL")
        .then(response => {
          this.accounts = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async getReports() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/generalLedger", this.params)
        .then(async response => {
          this.report = response.data;
          if (response.data.items && response.data.items.length > 0) {
            this.createPdf();
            this.createPdfIdr();
            this.view = true;
            if (response.data.items[0].currency !== "IDR") {
              this.viewIdr = true;
            }
          }

          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangeAccount(val) {
      this.params.id = val.accountId;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/generalLedgerExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-gl.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    setNewArray(items) {
      const array = [];
      items.forEach(x => {
        array.push([
          {
            content: x.date,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: x.description,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: x.reference,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: x.currency,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: this.formatPrice(x.debit),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.credit),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.balance),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    setNewArrayIdr(items) {
      const array = [];
      items.forEach(x => {
        array.push([
          {
            content: x.date,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: x.description,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: x.reference,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: "IDR",
            styles: { valign: "top", halign: "center" },
          },
          {
            content: this.formatPrice(x.debitIdr),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.creditIdr),
            styles: { valign: "top", halign: "right" },
          },
          {
            content: this.formatPrice(x.balanceIdr),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 10, 10);
      doc.text("GENERAL LEDGER REPORT IN RP", 10, 15);
      doc.text(`PERIODE : ${this.params.startDate} TO ${this.params.endDate}`, 10, 20);
      doc.text(`ACCOUNT : ${this.report.accountNumber}`, 10, 25);
      doc.text(`DESCRIPTION : ${this.report.accountName}`, 10, 30);

      doc.autoTable({
        margin: { top: 40, left: 10 },
        theme: "plain",
        head: [["DATE", "DESCRIPTION", "REFF", "CUR", "DEBET", "CREDIT", "BALANCE"]],
        body: [
          [
            {
              content: "",
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "SALDO AWAL",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.report.items[0].currency,
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.formatPrice(this.report.beginBalance),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          ...this.setNewArray(this.report.items),
          [
            {
              colSpan: 4,
              content: "TOTAL :",
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.report.totalDebit),
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.report.totalCredit),
              styles: { valign: "middle", halign: "right" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          fontSize: 7,
          overflow: "linebreak",
          overflowColums: "linebreak",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          fontStyle: "bold",
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 70 },
          2: { cellWidth: 30 },
          3: { cellWidth: 10 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          // etc
        },
      });

      this.url = doc.output("datauristring");
    },
    createPdfIdr() {
      const doc = new jsPDF();

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 10, 10);
      doc.text("GENERAL LEDGER REPORT IN RP", 10, 15);
      doc.text(`PERIODE : ${this.params.startDate} TO ${this.params.endDate}`, 10, 20);
      doc.text(`ACCOUNT : ${this.report.accountNumber}`, 10, 25);
      doc.text(`DESCRIPTION : ${this.report.accountName}`, 10, 30);

      doc.autoTable({
        margin: { top: 40, left: 10 },
        theme: "plain",
        head: [["DATE", "DESCRIPTION", "REFF", "CUR", "DEBET", "CREDIT", "BALANCE"]],
        body: [
          [
            {
              content: "",
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "SALDO AWAL",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: "IDR",
              styles: { valign: "middle", halign: "center" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "left" },
            },
            {
              content: this.formatPrice(this.report.beginBalanceIdr),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          ...this.setNewArrayIdr(this.report.items),
          [
            {
              colSpan: 4,
              content: "TOTAL :",
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.report.totalDebitIdr),
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.report.totalCreditIdr),
              styles: { valign: "middle", halign: "right" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          fontSize: 7,
          overflow: "linebreak",
          overflowColums: "linebreak",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          fontStyle: "bold",
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 70 },
          2: { cellWidth: 30 },
          3: { cellWidth: 10 },
          4: { cellWidth: 20 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          // etc
        },
      });

      this.urlIdr = doc.output("datauristring");
    },
  },
  mounted() {
    this.getAccounts();
  },
};
</script>

<style></style>
